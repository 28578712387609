import React from "react";
import styles from "./About2.module.css";
// import { FaDownload, FaVideo, FaUsers, FaInfoCircle } from "react-icons/fa";

// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About2 = () => {
  // const scrollContainerRef = useRef(null);
  // const skillsData = [
  //   { id: 1, name: "1000+ Download App", logo: <FaDownload /> },
  //   { id: 2, name: "1275+ Video Upload", logo: <FaVideo /> },
  //   { id: 3, name: "Connect with Leaders", logo: <FaUsers /> },
  //   { id: 4, name: "Stay Informed", logo: <FaInfoCircle /> },
  // ];

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 3000,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   cssEase: "linear",
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      <section className={styles.sectioncontainer}>
        <h1 className={styles.title}>
          Connecting Citizens with Their{" "}
          <span className={styles.highlight}>Leaders</span>
        </h1>

        <p className={styles.description}>
          In today’s fast-paced world, staying connected with those who govern
          us is crucial. Suniye Neta jee bridges the gap between citizens and
          political leaders, fostering open, transparent, and effective
          communication. Whether you want to voice a concern, share a
          suggestion, or stay updated with the latest policies, Suniye Netaji
          makes it possible—all at your fingertips.
        </p>
      </section>
      {/* card scrolling section */}

      {/* <div className={styles.featuresSection}>
        <div className={styles.skillsGrid}>
          <Slider {...settings}>
            {skillsData.map((skill) => (
              <div key={skill.id} className={styles.skillCard}>
                <div className={styles.skillLogo}>{skill.logo}</div>
                <p className={styles.skillName}>{skill.name}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
    </>
  );
};

export default About2;
