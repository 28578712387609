import React from "react";
// import React, { useEffect, useRef, useMemo } from "react";
import styles from "./News.module.css";
import ImageAssets from "../../../assets/ImageAssets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const features = [
  {
    img: ImageAssets.nesw2,
    title:
      "NDA Party Celebrates Victorious Win with Citizens Across the Nation",
    description:
      "The NDA party's resounding victory in the recent elections has sparked jubilant celebrations nationwide.",
  },
  {
    img: ImageAssets.news3,
    title: "Congress Party's Victory Citizens Rejoice as MP Celebrates",
    description:
      "The Congress Party's recent electoral win has been met with widespread jubilation across Madhya Pradesh. ",
  },

  {
    img: ImageAssets.news5,
    title: "BJP Leaders Meet Tamil Nadu CM and Home Minister Amit Shah",
    description:
      "In a significant political development, senior BJP leaders convened with Tamil Nadu Chief Minister M.K. Stalin a ",
  },
];

const News = () => {
  // const marqueeRef = useRef(null);
  // const content = useMemo(
  //   () => [
  //     "जेपी नड्डा के आवास पर चल रही मीटिंग खत्म, प्रहलाद जोशी और पीयूष गोयल निकले",
  //     "Beed Maharashtra Constituency 2024 Lok Sabha Elections 2024 Who are the candidates in fray?",
  //     "72 सदस्यीय एनडीए मंत्रिमंडल ने कार्यभार संभाला",
  //     "नड्डा के मंत्रिमंडल में शामिल होने से भाजपा पार्टी संरचना में करेगी बदलाव",
  //     "Poll Losers Named Ministers, Winners Miss Out: Many Surprises In Modi 3.0",
  //   ],
  //   []
  // );

  // useEffect(() => {
  //   const handleAnimationStart = () => {
  //     marqueeRef.current.innerText = content[0];
  //   };

  //   const handleAnimationIteration = () => {
  //     const currentText = marqueeRef.current.innerText;
  //     const currentIndex = content.indexOf(currentText);
  //     const newIndex = (currentIndex + 1) % content.length;
  //     marqueeRef.current.innerText = content[newIndex];
  //   };

  //   const marqueeElement = marqueeRef.current;

  //   marqueeElement.addEventListener("animationstart", handleAnimationStart);
  //   marqueeElement.addEventListener(
  //     "animationiteration",
  //     handleAnimationIteration
  //   );

  //   marqueeElement.classList.remove(styles.paused);

  //   return () => {
  //     marqueeElement.removeEventListener(
  //       "animationstart",
  //       handleAnimationStart
  //     );
  //     marqueeElement.removeEventListener(
  //       "animationiteration",
  //       handleAnimationIteration
  //     );
  //   };
  // }, [content]);
  const settings = {
    // dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.newsbanner}>
        <div className={styles.textContainer}>
          <h1>
            अब पाओ <span className={styles.mobileColor}>राजनीति</span> की सारी
            जानकरी अपने <span className={styles.mobileColor}>Mobile</span> से{" "}
          </h1>
          <p> राजनीति की सारी जानकरी ऑनलाइन मिलेगी </p>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={ImageAssets.suniyenetajiapp_girlPromossion}
            alt="Banner"
            className={styles.bannerImage}
          />
        </div>
      </div>
      {/* section 2 */}
      <div className={styles.bannerNews}>
        {/* <div className={styles.breakingNews}>
          <div className={styles.breakingNewsText}>BREAKING NEWS</div>
          <div className={styles.marqueeContainer}>
            <div
              className={`${styles.marquee} ${styles.paused}`}
              ref={marqueeRef}
            ></div>
          </div>
        </div> */}

        <div className={styles.content}>
          <h1>Overview</h1>
          <h2>जनता की आवाज</h2>
          <p className={styles.suniyeNetaji_graph}>
            <span className={styles.suniyeNetajee}>Suniye Neta Jee App</span> is
            an innovative platform for direct communication between leaders and
            citizens. It allows citizens to report issues, receive updates, and
            provide feedback. Leaders can respond swiftly, ensuring transparency
            and accountability. Our app strengthens community ties by fostering
            real-time engagement and effective problem-solving, ultimately
            empowering citizens and enhancing governance.
          </p>
          {/* <button
            className={`${styles.trendingButton} ${styles.trendingButton}`}
          >
            TRENDING NEWS
          </button> */}
        </div>
      </div>
      {/*  news section*/}

      <div className={styles.featuresSection}>
        <Slider {...settings}>
          {features.map((feature, index) => (
            <div key={index} className={styles.cardContainer}>
              <div className={styles.card}>
                <img
                  src={feature.img}
                  alt={feature.title}
                  className={styles.image}
                />
              </div>
              <div className={styles.textContainerNews}>
                <h2>{feature.title}</h2>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </Slider>
        {/* <button className={`${styles.trendingButton} ${styles.trendingButton}`}>
          OVERVIEW ALL TYPE
        </button> */}
      </div>

      {/* online business container */}
      <div className={styles.banner}>
        <div className={styles.textContainer}>
          <h1>
            अगर आप है अपने छेत्रीय नेता से परेशान , चाहिए समाधान , तो आइये 
            <br />
            <span className={styles.highlight}>suniye neta jee app</span>
          </h1>
          {/* <p>अब डिजिटल हो गया, सब मिलेगा ऑनलाइन</p> */}
          <button
            className={`${styles.trendingButton} ${styles.trendingButton}`}
          >
            जनता की आवाज
          </button>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={ImageAssets.digital2}
            alt="Promotion"
            className={styles.image}
          />
        </div>
      </div>
    </>
  );
};

export default News;
