import React from "react";
import Banner from "./Banner/Banner";
import News from "./News/News";

import Vote from "./Vote/Vote";
import Contucts from "./Contactsus/Contucts";

const Home = () => {
  return (
    <div>
      <Banner />
      <News />
      <Vote />
      <Contucts />
    </div>
  );
};

export default Home;
