import React from "react";
import styles from "./About.module.css";
import ImageAssets from "../../assets/ImageAssets";
import About2 from "./About2/About2";
import AboutCard from "./AboutCard/AboutCard";
const About = () => {
  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.textContainer}>
            <h1 className={styles.title}>
              About Suniye Neta jee{" "}
              <span className={styles.highlight}>App</span>
            </h1>
            <p className={styles.description}>
              The <span className={styles.appLink}>Suniye Neta jee app</span>{" "}
              empowers citizens to directly communicate with political leaders,
              enabling transparent discussions on pressing issues, fostering
              accountability, and bridging the gap between governance and the
              public. Click on{" "}
              <span className={styles.appLink}>Download App</span> button to get
              started. Available on Android platforms for free.
            </p>
            <div className={styles.buttons}>
              <button className={`${styles.button} ${styles.meet}`}>
                JOIN COMMUNITY
              </button>
              <button className={`${styles.downloadbutton} ${styles.download}`}>
                DOWNLOAD APP <span className={styles.arrow}>↗</span>
              </button>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={ImageAssets.netaji_pic}
              alt="Web Developer"
              className={styles.image}
            />
          </div>
        </header>
      </div>
      <About2 />
      <AboutCard />
    </>
  );
};

export default About;
