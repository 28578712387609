import React from "react";
import styles from "./BusinessCard.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageAssets from "../../../assets/ImageAssets";

const BusinessCard = () => {
  const features = [
    {
      img: ImageAssets.digitalPic1,
      title: "Mlm Business Plan ",
      description:
        "MLM Software Services — Start Your Own MLM Company.We help you to drive up your MLM business. Book A Free Demo.",
    },
    {
      img: ImageAssets.digitalPic2,
      title: "Digital Marketing ",
      description:
        "Digital marketing is a means of promoting and selling products and services through the internet, mobile devices, social media, search engines, ..",
    },

    {
      img: ImageAssets.digitalPic3,
      title: "Network Marketing",
      description:
        "Multi-level marketing (MLM), also called network marketing or pyramid selling, is a controversial marketing strategy for the sale of products or services in ..",
    },
  ];
  const settings = {
    // dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.featuresSection}>
      <Slider {...settings}>
        {features.map((feature, index) => (
          <div key={index} className={styles.cardContainer}>
            <div className={styles.card}>
              <img
                src={feature.img}
                alt={feature.title}
                className={styles.image}
              />
            </div>
            <div className={styles.textContainerNews}>
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BusinessCard;
