import React from "react";
import styles from "./PoliticesCard.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageAssets from "../../../assets/ImageAssets";

const PoliticesCard = () => {
  const features = [
    {
      img: ImageAssets.cia_pic,
      title: "India CAA  ",
      description:
        "The indian society is divided into numerous castes and sub-castes, inspite of these diversities, still remains a largely unified society and political entit .",
    },
    {
      img: ImageAssets.Panjab_news,
      title: "Important issues  ",
      description:
        "Terrorism, Naxalism, religious violence and caste-related violence are the important issues that affect the political environment of the Indian nation",
    },

    {
      img: ImageAssets.news5,
      title: "Meeting All Politican",
      description:
        "Clearly articulate the purpose of meeting the politicians. For example, is it to seek endorsements, gather support, propose policy integration, or simply inform them about the app’s benefits",
    },
  ];
  const settings = {
    // dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.featuresSection}>
      <Slider {...settings}>
        {features.map((feature, index) => (
          <div key={index} className={styles.cardContainer}>
            <div className={styles.card}>
              <img
                src={feature.img}
                alt={feature.title}
                className={styles.image}
              />
            </div>
            <div className={styles.textContainerNews}>
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PoliticesCard;
