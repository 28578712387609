import React, { useState } from "react";
import styles from "./Navbar.module.css";
import ImageAssets from "../../assets/ImageAssets";
// import Popup from "../Popup/Popup";
// import Register from "../Register/Register";
// import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaUsers,
  FaInfoCircle,
  FaEnvelope,
  FaShieldAlt,
} from "react-icons/fa";
const Navbar = () => {
  //   const [isPopupOpen, setIsPopupOpen] = useState(false);
  //   const [isRegister, setIsRegister] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  //   const openPopup = () => {
  //     setIsPopupOpen(true);
  //   };

  //   const closePopup = () => {
  //     setIsPopupOpen(false);
  //   };
  //   const openRegister = () => {
  //     setIsRegister(true);
  //   };
  //   const closeRegister = () => {
  //     setIsRegister(false);
  //   };
  const handleBurgerClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };
  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/">
          <div className={styles.logo}>
            <img src={ImageAssets.logo3} alt="Logo" />
          </div>
        </Link>
        <div className={styles.burgerIcon} onClick={handleBurgerClick}>
          {isOpen ? (
            <FaTimes className={styles.icon} />
          ) : (
            <FaBars className={styles.icon} />
          )}
        </div>
        <div className={`${styles.navItems} ${isOpen ? styles.open : ""}`}>
          <ul className={styles.navLinks}>
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <FaHome
                  className={styles.iconSidebar}
                  style={{
                    color: location.pathname === "/" ? "white" : "#ffb804",
                  }}
                />
                <p
                  style={{
                    color: location.pathname === "/" ? "white" : "#ffb804",
                  }}
                >
                  Home
                </p>
              </li>
            </Link>
            <Link
              to="/politics"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <FaUsers
                  className={styles.iconSidebar}
                  style={{
                    color:
                      location.pathname === "/politics" ? "white" : "#ffb804",
                  }}
                />
                <p
                  style={{
                    color:
                      location.pathname === "/politics" ? "white" : "#ffb804",
                  }}
                >
                  Politics
                </p>
              </li>
            </Link>
            <Link
              to="/about"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <FaInfoCircle
                  className={styles.iconSidebar}
                  style={{
                    color: location.pathname === "/about" ? "white" : "#ffb804",
                  }}
                />
                <p
                  style={{
                    color: location.pathname === "/about" ? "white" : "#ffb804",
                  }}
                >
                  About
                </p>
              </li>
            </Link>
            <Link
              to="/contacts"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <FaEnvelope
                  className={styles.iconSidebar}
                  style={{
                    color:
                      location.pathname === "/contacts" ? "white" : "#ffb804",
                  }}
                />
                <p
                  style={{
                    color:
                      location.pathname === "/contacts" ? "white" : "#ffb804",
                  }}
                >
                  Contact Us
                </p>
              </li>
            </Link>
            <Link
              to="/privacy"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <li>
                <FaShieldAlt
                  className={styles.iconSidebar}
                  style={{
                    color:
                      location.pathname === "/privacy" ? "white" : "#ffb804",
                  }}
                />
                <p
                  style={{
                    color:
                      location.pathname === "/privacy" ? "white" : "#ffb804",
                  }}
                >
                  Privacy Policy
                </p>
              </li>
            </Link>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
