import React from "react";
import styles from "./Banner.module.css";
import ImageAssets from "../../../assets/ImageAssets";
import { motion } from "framer-motion";
const Banner = () => {
  const words = [
    "Now",
    "Get",
    <span className={styles.green}>A to Z</span>,
    "Information",
    "of",
    "all",
    <span className={styles.green}>Political</span>,
    "issues",
    "of",
    <span className={styles.green}>India</span>,
  ];

  const AnimatedText = ({ words }) => {
    return words.map((el, i) => (
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.25,
          delay: i / 10,
        }}
        key={i}
      >
        {el}{" "}
      </motion.span>
    ));
  };
  return (
    <div className={styles.banner}>
      <div className={styles.textContainer}>
        <h1 className={styles.heading}>
          <AnimatedText words={words} />
        </h1>
        <h5>
          आपकी आवाज़ बनेगी आपकी पहचान ,{" "}
          <span className={styles.green}>suniye neta jee app</span> के साथ।{" "}
        </h5>
        <p>जनता की आवाज सुनने के लिए तैयार है सुनिए नेता जी मोबाइल ऐप पर </p>

        <button className={`${styles.supportButton} ${styles.supportbtnHover}`}>
          SUPPORT
        </button>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={ImageAssets.logo2}
          alt="Banner"
          className={styles.bannerImage}
        />
      </div>
    </div>
  );
};

export default Banner;
