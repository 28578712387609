import React from "react";
import styles from "./Privacy.module.css";
import ImageAssets from "../../assets/ImageAssets";
import { FaHandPointRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const Privacy = () => {
  return (
    <>
      <div className={styles.privacyContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.heading}> Privacy Policy</h1>
          <h5>
            This privacy policy applies to the suniyenetajee app (hereby
            referred to as "Application") for mobile devices that was created by
            suniyenetajee (hereby referred to as "Service Provider") as a Free
            service. This service is intended for use "AS IS".{" "}
          </h5>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={ImageAssets.privacy}
            alt="Banner"
            className={styles.bannerImage}
          />
        </div>
      </div>

      {/* Interpretation and Definitions */}

      <div className={styles.profileContainer}>
        <div className={styles.profileHeader}>
          <h1 className={styles.profileTitle}>
            Information Collection and Use
          </h1>
          <p className={styles.profileLocation}>
            The Application collects information when you download and use it.
            This information may include information such as
          </p>
          <p>
            Your device's Internet Protocol address (e.g. IP address) The pages
            of the Application that you visit, the time and date of your visit,
            the time spent on those pages The time spent on the Application The
            operating system you use on your mobile device
          </p>
          <p className={styles.profileDates}>
            The Application does not gather precise information about the
            location of your mobile device.
          </p>

          <ul className={styles.profileList}>
            <li>
              <FaHandPointRight color="#128c78" size={24} />
              The Service Provider may use the information you provided to
              contact you from time to time to provide you with important
              information, required notices and marketing promotions.
            </li>
            <li>
              <FaHandPointRight color="#128c78" size={24} />
              For a better experience, while using the Application, the Service
              Provider may require you to provide us with certain personally
              identifiable information. The information that the Service
              Provider request will be retained by them and used as described in
              this privacy policy.
            </li>
          </ul>
        </div>

        {/* <div className={styles.profileContainer}> */}
        <div className={styles.profileHeader}>
          <h1 className={styles.profileTitle}>Third Party Access</h1>
          <p className={styles.profileLocation}>
            Only aggregated, anonymized data is periodically transmitted to
            external services to aid the Service Provider in improving the
            Application and their service. The Service Provider may share your
            information with third parties in the ways that are described in
            this privacy statement.
          </p>
          <p>
            Please note that the Application utilizes third-party services that
            have their own Privacy Policy about handling data. Below are the
            links to the Privacy Policy of the third-party service providers
            used by the Application:
          </p>
          <ul className={styles.profileList}>
            <li>
              <FaHandPointRight color="#128c78" size="1.5em" />
              Google Play Services
            </li>
            <li>
              <FaHandPointRight color="#128c78" size="1.5em" />
              Facebook
            </li>
            <li>
              <FaHandPointRight color="#128c78" size="1.5em" />
              Unity
            </li>
            <h1 className={styles.profileTitle}>
              The Service Provider may disclose User Provided and Automatically
              Collected Information:
            </h1>
            <p className={styles.profileLocation}>
              as required by law, such as to comply with a subpoena, or similar
              legal process; when they believe in good faith that disclosure is
              necessary to protect their rights, protect your safety or the
              safety of others, investigate fraud, or respond to a government
              request; with their trusted services providers who work on their
              behalf, do not have an independent use of the information we
              disclose to them, and have agreed to adhere to the rules set forth
              in this privacy statement.
            </p>
          </ul>
        </div>
        {/* </div> */}

        {/* Usage Data */}

        <div className={styles.usageDataHeader}>
          <h1 className={styles.usageDataTitle}>Opt-Out Rights</h1>
          <p className={styles.usageDataSection}>
            You can stop all collection of information by the Application easily
            by uninstalling it. You may use the standard uninstall processes as
            may be available as part of your mobile device or via the mobile
            application marketplace or network.
          </p>
        </div>
        {/* Information from Third-Party Social Media Services */}

        <div className={styles.usageDataHeader}>
          <h1 className={styles.usageDataTitle}>Children</h1>
          <p className={styles.usageDataSection}>
            The Service Provider does not use the Application to knowingly
            solicit data from or market to children under the age of 13.
          </p>

          <ul className={styles.profileList}>
            <li>
              <FaHandPointRight color="#128c78" size="1.5em" />
              The Application does not address anyone under the age of 13. The
              Service Provider does not knowingly collect personally
              identifiable information from children under 13 years of age. In
              the case the Service Provider discover that a child under 13 has
              provided personal information, the Service Provider will
              immediately delete this from their servers. If you are a parent or
              guardian and you are aware that your child has provided us with
              personal information, please contact the Service Provider () so
              that they will be able to take the necessary actions.
            </li>
          </ul>

          <p className={styles.usageDataSection}>
            This privacy policy is effective as of 2024-07-08
          </p>
        </div>

        {/*  */}

        <div className={styles.usageDataHeader}>
          <h1 className={styles.usageDataTitle}>Data Retention Policy</h1>
          <p className={styles.usageDataSection}>
            The Service Provider will retain User Provided data for as long as
            you use the Application and for a reasonable time thereafter. If
            you'd like them to delete User Provided Data that you have provided
            via the Application, please contact them at
            support@suniyenetajee.com and they will respond in a reasonable time
          </p>
        </div>

        {/*  */}

        <div className={styles.usageDataHeader}>
          <h1 className={styles.usageDataTitle}>Your Consent</h1>
          <p className={styles.usageDataSection}>
            By using the Application, you are consenting to the processing of
            your information as set forth in this Privacy Policy now and as
            amended by us.
          </p>
        </div>

        {/* Contact Us */}

        <div className={styles.contactHeader}>
          <h1 className={styles.contactTitle}>Contact Us</h1>
          <p className={styles.contactSection}>
            If you have any questions regarding privacy while using the
            Application, or have questions about the practices, please contact
            the Service Provider via email at{" "}
            <a
              href="mailto:support@suniyenetajee.com"
              className={styles.emailsupport}
            >
              support@suniyenetajee.com
            </a>
          </p>
          <Link to="/contacts">
            <button className={styles.contactButton}>CONTACT US</button>
          </Link>
        </div>
      </div>

      {/* </div> */}
    </>
  );
};

export default Privacy;
