import React from "react";
import ImageAssets from "../../../assets/ImageAssets";
import styles from "./Vote.module.css";

const Vote = () => {
  return (
    <div className={styles.newswomancontainer}>
      <div className={styles.newswomanmainImage}>
        <img
          src={ImageAssets.mera_vate}
          alt="Newswoman"
          className={styles.womanimage}
        />
      </div>
      <div className={styles.newswomanbottomImages}>
        <div className={styles.newswomanbottomImage}>
          <img src={ImageAssets.woman_vote} alt="womanvote" />
          <p>लोकसभा चुनाव मतदान करती महिलाएं</p>
        </div>
        <div className={styles.newswomanbottomImage}>
          <img src={ImageAssets.youth_vote} alt="youthvote" />
          <p>
            Youth Enthusiastically Votes and Celebrates with Post-Vote Selfies
          </p>
        </div>
        <div className={styles.newswomanbottomImage}>
          <img src={ImageAssets.rajsthan_vate} alt="rajthanvote" />
          <p>राजस्थान की महिलाएं मतदान के दौरान जोश और उत्साह का नजारा</p>
        </div>
      </div>
    </div>
  );
};

export default Vote;
