import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Politics from "./pages/Politices/Politics";
import ContactForm from "./pages/ContactForm/ContactForm";
import About from "./pages/About/About";
import Business from "./pages/Business/Business";
import Privacy from "./pages/Privacy/Privacy";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/politics" element={<Politics />} />
          <Route path="/contacts" element={<ContactForm />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/business" element={<Business />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
