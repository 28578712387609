import React, { useRef } from "react";
import styles from "./Politics.module.css";
import ImageAssets from "../../assets/ImageAssets";
import PoliticesCard from "./PoliticesCard/PoliticesCard";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Primeministers from "./Primeminister/Primeministers";
const Politics = () => {
  const slideRef = useRef(null);
  const data = [
    { img: ImageAssets.bannerpoli2 },
    { img: ImageAssets.bannerpoli1 },
    { img: ImageAssets.bannerpoli3 },
    // { img: ImageAssets.politicalBanner14 },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* scrolling Banner */}
      <div className={styles.carouselContainer}>
        <Slider {...settings}>
          {data.map((data, index) => (
            <div key={index} className={styles.carousel}>
              <div ref={slideRef} className={styles.slides}>
                <img
                  className={styles.slideImage}
                  src={data.img}
                  alt={`Slide ${index}`}
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* <Primeministers /> */}

      {/* second banner */}
      <div className={styles.banner}>
        <div className={styles.textContainer}>
          <h1 className={styles.heading}>
            अब जनता की आवाज़ सुनने के लिए तैयार है{" "}
            <span className={styles.green}>सुनिए नेताजी मोबाइल ऐप</span>{" "}
          </h1>
          <p>अब पाओ राजनीति की सारी जानकरी अपने Mobile से </p>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={ImageAssets.logo13}
            alt="Banner"
            className={styles.bannerImage}
          />
        </div>
      </div>

      {/* What do you need to find? */}
      <div className={styles.content}>
        <h1>Political issues of the India</h1>
        <p className={styles.suniyeNetaji_graph}>
          India's political risk is low to moderate. The ongoing Kashmir
          conflict between India and Pakistan poses ongoing military risks,
          while longstanding border issues with China remain prominent.
        </p>
      </div>
      <PoliticesCard />
    </>
  );
};

export default Politics;
