import React from "react";
import styles from "./ContactForm.module.css";
import ImageAssets from "../../assets/ImageAssets";

const ContactForm = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.textContainer}>
        <h1 className={styles.heading}>Contact Form</h1>
        <p>
          The <span className={styles.applink}>Suniye Neta jee app </span>
          is dedicated to connecting citizens with their leaders and enhancing
          community engagement. We’re committed to assisting you with any
          queries or support you need. Feel free to contact us during our
          working hours.
        </p>
        <input
          type="text"
          placeholder="Your full name"
          className={styles.input}
        />
        <input
          type="text"
          placeholder="your mobile number"
          className={styles.input}
        />
        <input type="text" placeholder="Your Email" className={styles.input} />
        <textarea
          placeholder="What do you want to know? Write it here..."
          className={styles.textarea}
        ></textarea>
        <button
          className={`${styles.supportButton} ${styles.supportButtonHover}`}
        >
          SUBMIT
        </button>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={ImageAssets.contact_banner}
          alt="Banner"
          className={styles.bannerImage}
        />
      </div>
    </div>
  );
};

export default ContactForm;
