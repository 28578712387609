import React from "react";
import styles from "./AboutCard.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ImageAssets from "../../../assets/ImageAssets";
import {
  FaVideo,
  FaUsers,
  FaInfoCircle,
  FaUserTie,
  FaVoteYea,
} from "react-icons/fa";

const AboutCard = () => {
  const features = [
    {
      icon: <FaUserTie size={50} color="#128c78" />,
      title: "1000+ User ",
    },
    {
      icon: <FaVideo size={50} color="#128c78" />,
      title: "1275+ Video Upload",
    },
    {
      icon: <FaUsers size={50} color="#128c78" />,
      title: "Connect with Leaders",
    },
    {
      icon: <FaInfoCircle size={50} color="#128c78" />,
      title: "Stay Informed ",
    },

    {
      icon: <FaVoteYea size={50} color="#128c78" />,
      title: "Vote and Participate ",
    },
  ];
  const settings = {
    // dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.featuresSection}>
      <Slider {...settings}>
        {features.map((feature, index) => (
          <div key={index} className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.iconContainer}>{feature.icon}</div>
              <div className={styles.textContainerNews}>
                <h2>{feature.title}</h2>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AboutCard;
