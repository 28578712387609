import React from "react";
import styles from "./Business.module.css";
import ImageAssets from "../../assets/ImageAssets";
import BusinessCard from "./BusinessCard/BusinessCard";
const Business = () => {
  return (
    <>
      <div className={styles.bannerContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.heading1}>कारोबार छोटा हो, या बड़ा</h1>
          <h2 className={styles.heading2}>
            <span className={styles.highlight}>Online</span> आओ,{" "}
            <span className={styles.highlight}>Business</span> बढ़ाओ
          </h2>
          <p className={styles.paragraph}>
            सुनिये नेता जी Mobile App अब डिजिटल हो गया, सब मिलेगा ऑनलाइन
          </p>

          <p className={styles.paragraph}>
            Promote your business through targeted advertisements and community
            announcements within the app.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={ImageAssets.businessBanner}
            alt="Banner"
            className={styles.bannerImage}
          />
        </div>
      </div>

      {/* What do you need to find? */}
      <div className={styles.content}>
        <h1>What do you need to find?</h1>
        <p className={styles.suniyeNetaji_graph}>
          Suneya neta ji is the biggest online marketplace of india district
          which helps manufacturers, suppliers, and exporters to do business
          with each other on a common, reliable, and transparent platform. Apna
          india is the largest free and paid online professional resource, bus ,
          and busine ss listing website for local shoppers and merchants. Find
          here Quality Items, Transportation, Business Leads, Manufacturers,
          Suppliers, Exporters, Local & In ternational Buyers here
        </p>
      </div>
      <BusinessCard />
    </>
  );
};

export default Business;
